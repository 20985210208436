<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="150px" @submit.native.prevent>
            <el-form-item label="序号：" prop="serialNumber">
                <el-input-number  :step="1" :min="1" :max="9999" v-model="inputForm.serialNumber" placeholder="排序号"></el-input-number>
            </el-form-item>
            <el-form-item label="展览团队名称：" prop="name">
                <el-input v-model.trim="inputForm.name" placeholder="请输入" maxlength="50"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                title:"新建",
                visible: false,
                loading: false,
                inputForm: {
                    id:"",
                    serialNumber:"",
                    name: '',
                    delFlag :"",
                    informationId :"",
                },
                dataRule: {
                    name: [
                        {required: true, message: '展览团队名称不能为空', trigger: 'blur'}
                    ],
                    serialNumber: [
                        {required: true, message: '顺序号不能为空', trigger: 'blur'}
                    ]
                },
                method:""
            }
        },
        methods: {
            init (method,row) {
                this.method = method
                this.visible = true
                this.inputForm.id = ""
                this.title="新建"
                if(method == "add"){
                    this.inputForm.informationId = row
                }
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    
                    if(method == "copy"){
                        this.title="复制"
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                    if (method === 'edit') { // 修改
                        this.title="编辑"
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.zlzs.exhibitionTeamSave
                        let type = "post"
                        if(this.method == "copy"){
                            api = this.api.zlzs.exhibitionTeamCopy
                        }
                        if(this.method == "edit"){
                            api = this.api.zlzs.exhibitionTeamUpdateById
                            type = "put"
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }
                })
            }
        }
    }
</script>
